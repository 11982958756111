<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>记录查询</el-breadcrumb-item>
      <el-breadcrumb-item
        v-if="$route.query.totalTime"
        :to="{ path: '/Billdaysummary' }"
        >账单日汇总</el-breadcrumb-item
      >
      <el-breadcrumb-item>订单记录</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 查询 -->
      <div
        style="
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
        "
      >
        <label style="margin: 10px" for=""
          >停车场：
          <el-select
            v-model="query.pk_id"
            @change="pkIdChange"
            filterable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              v-for="item in PkInfoSelect"
              :key="item.id"
              :label="item.pkName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </label>
        <label style="margin: 10px 50px" for=""
          >车牌：
          <el-input
            style="width: 150px"
            size="small"
            placeholder="请输入车牌号"
            v-model.trim="query.plateNo"
            clearable
        /></label>
        <label style="margin: 10px" for=""
          >是否使用优惠券：
          <el-select
            v-model="query.isCoupon"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >支付状态：
          <el-select
            v-model="query.payStatus"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in payStatusdd"
              :key="index"
            ></el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >支付方式：
          <el-select
            v-model="query.paidType"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              :label="item.name"
              :value="item.code"
              v-for="(item, index) in payment"
              :key="index"
            ></el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >开票状态：
          <el-select
            v-model="query.invoiceStatus"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              :label="item"
              :value="index"
              v-for="(item, index) in invoice"
              :key="index"
            ></el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for=""
          >订单号：
          <el-input
            style="width: 200px"
            size="small"
            placeholder="请输入订单号"
            v-model="query.orderNo"
            clearable
        /></label>

        <label style="margin: 10px" for=""
          >0元账单：
          <el-select
            v-model="query.freeFlag"
            clearable
            placeholder="请选择"
            style="width: 200px"
            size="small"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="(item, index) in billshowId"
              :key="index"
            ></el-option>
          </el-select>
        </label>
        <label style="margin: 10px" for="" v-if="!$route.query.totalTime"
          >订单时间：<el-date-picker
            v-model="inTime"
            @change="inTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            placeholder="选择日期时间"
            :clearable="false"
          >
          </el-date-picker>
          至
          <el-date-picker
            v-model="outTime"
            @change="outTimechange"
            type="datetime"
            value-format="timestamp"
            size="small"
            default-time="23:59:59"
            placeholder="选择日期时间"
            :clearable="false"
          >
          </el-date-picker
        ></label>
        <div v-show="showHandover == true">
          <label style="margin: 10px" for=""
            >车辆类型：
            <el-select
              v-model="query.plateType"
              clearable
              placeholder="请选择"
              style="width: 200px"
              size="small"
            >
              <el-option
                :label="item.name"
                :value="item.code"
                v-for="(item, index) in plateType"
                :key="index"
              ></el-option>
            </el-select>
          </label>
          <label style="margin: 10px" for=""
            >用户分组：
            <el-select
              v-model="query.userGroupId"
              clearable
              placeholder="请选择"
              style="width: 200px"
              size="small"
            >
              <el-option
                :label="item.groupName"
                :value="item.id"
                v-for="(item, index) in userGroupId"
                :key="index"
              ></el-option>
            </el-select>
          </label>
          <label style="margin: 5px" for="">
            年份:
            <el-date-picker
              size="small"
              v-model="query.t_year"
              type="year"
              placeholder="选择年份"
              format="yyyy"
              value-format="yyyy"
              style="width: 185px"
              :picker-options="pickerOptions"
              @change="tYearchange"
            />
          </label>
        </div>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="small"
          @click="orderInfocx"
          >查询</el-button
        >
        <el-button size="small" @click="orderInfocz">重置</el-button>
        <el-button
          type="primary"
          size="small"
          :loading="isShowloading"
          @click="orderInfoexcel"
          >导出</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="correctOrder"
          v-show="showCorrect"
          >纠正跨天订单</el-button
        >
        <div class="deployment">
          <div v-show="showHandover == false" @click="showHandover = true">
            展开<i class="el-icon-arrow-down"></i>
          </div>
          <div v-show="showHandover == true" @click="showHandover = false">
            收起<i class="el-icon-arrow-up"></i>
          </div>
        </div>
      </div>
      <!-- 表格 -->
      <div v-if="cashzje" style="text-align: right; font-weight: 600">
        订单总金额:{{ cashzje.total ? cashzje.total : '0' }}元，实际优惠抵扣:{{
          cashzje.coupon ? cashzje.coupon : '0'
        }}元，应收:{{
          cashzje.receivableMoney ? cashzje.receivableMoney : '0'
        }}元，实收:{{ cashzje.actually ? cashzje.actually : '0' }}元，微信:{{
          cashzje.wxPay ? cashzje.wxPay : '0'
        }}元，支付宝:{{
          cashzje.alipay ? cashzje.alipay : '0'
        }}元，建行聚合支付:{{
          cashzje.ccb ? cashzje.ccb : '0'
        }}元，杭州城市大脑:{{
          cashzje.hangzhouCity ? cashzje.hangzhouCity : '0'
        }}元，杭州银行:{{
          cashzje.hzBank ? cashzje.hzBank : '0'
        }}元，现金支付:{{ cashzje.cash ? cashzje.cash : '0' }}元，ETC支付:{{
          cashzje.etc ? cashzje.etc : '0'
        }}元, P+R支付:{{ cashzje.pR ? cashzje.pR : '0' }}元，免费放行:{{
          cashzje.freeMoney ? cashzje.freeMoney : '0'
        }}元, 杭州城市大脑ETC:{{ cashzje.hzEtc ? cashzje.hzEtc : '0' }}元,
        工商银行支付:{{ cashzje.icbc ? cashzje.icbc : '0' }}元, 储值车支付:{{
          cashzje.saveNumCar ? cashzje.saveNumCar : '0'
        }}元
      </div>
      <div v-else style="text-align: center; font-weight: 600">
        订单金额统计中<i class="el-icon-loading"></i>
      </div>
      <hr />
      <transition appear tag="div">
        <el-table
          :data="tableData"
          :row-class-name="tableRowClassName"
          @sort-change="sortchange"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="编号"
            width="50"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateNo"
            label="车牌号"
            width="75"
            fixed="left"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="plateType"
            label="车辆类型"
            width="100"
          >
            <template slot-scope="scope">
              <span>{{ findQuery(scope.row.plateType, plateType) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inTime"
            label="进场时间"
            sortable="custom"
            width="130"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.inTime">{{
                conversionTime(scope.row.inTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="outTime"
            label="离场时间"
            sortable="custom"
            width="130"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.outTime">{{
                conversionTime(scope.row.outTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="pkTime"
            label="停车时长"
            width="160"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.inTime && scope.row.outTime">{{
                timeFn(scope.row.inTime, scope.row.outTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="inDoorName"
            label="进场通道"
            width="75"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="outDoorName"
            label="离场通道"
            width="75"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderTotalMoney"
            label="订单金额"
            width="75"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponName"
            label="电子优惠券名称"
            width="110"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.shopName && scope.row.couponName">{{
                '【' + scope.row.shopName + '】' + scope.row.couponName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="优惠券金额"
            width="85"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="couponMoney"
            label="实际抵扣金额"
            width="95"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.orderTotalMoney > scope.row.couponMoney
                  ? scope.row.couponMoney
                  : scope.row.orderTotalMoney
              }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            header-align="left"
            align="left"
            prop="receivableMoney"
            label="应付金额"
            width="75"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.receivableMoney }}</span>
            </template>
          </el-table-column> -->
          <el-table-column
            header-align="left"
            align="left"
            prop="actuallyPayMoney"
            label="实收金额"
            width="75"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="payScenes"
            label="支付场景"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.payScenes }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="payStatuses"
            label="支付方式"
            width="75"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.payStatuses }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="payStatus"
            label="支付状态"
            width="75"
          >
            <template slot-scope="scope">
              <span>{{
                findQueryzfzt(
                  scope.row.payStatus,
                  scope.row.isHzcityPay,
                  payStatusdd
                )
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="invoiceStatus"
            label="开票状态"
            width="75"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.invoiceStatus === 0">未开票</span>
              <span v-if="scope.row.invoiceStatus === 1">已开票</span>
              <span v-if="scope.row.invoiceStatus === 2">开票失败</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="orderNo"
            label="订单号"
            width="140"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="payOrderNo"
            label="支付单号"
            width="140"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="operator"
            label="操作员"
            width="70"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="remarks"
            label="放行备注"
            width="230"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.remarks">{{
                '免费通行原因：' + JSON.parse(scope.row.remarks).content
              }}</span
              ><br />
              <span v-if="scope.row.remarks">{{
                '免费通行时间：' +
                conversionTime(JSON.parse(scope.row.remarks).createTime)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="260"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="invoiceManual(scope.row)"
                size="mini"
                >手动开票</el-button
              >
              <el-button
                type="text"
                @click="queryParkings(scope.row.id)"
                size="mini"
                >停车记录</el-button
              >
              <el-button
                type="text"
                @click="queryPayment(scope.row.id)"
                size="mini"
                >支付日志</el-button
              >
              <el-button
                type="text"
                @click="queryOrder(scope.row.id)"
                size="mini"
                >订单详情</el-button
              >
              <el-button
                type="text"
                @click="billingRecord(scope.row.id)"
                size="mini"
                >开票记录</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="query.current"
        :page-sizes="[10, 50, 100]"
        :page-size="query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 手动开票 -->
    <el-dialog
      title="手动开票"
      :visible.sync="isShowInvoice"
      width="60%"
      v-el-drag-dialog
      @close="invoiceclose"
    >
      <el-form
        label-position="left"
        label-width="120px"
        :model="invoices"
        :rules="rulesinvoice"
        ref="formName"
      >
        <el-form-item label="抬头类型" prop="invoiceType">
          <el-select
            v-model="invoices.invoiceType"
            placeholder="请选择"
            @change="changeInvoiceType"
          >
            <el-option
              v-for="item in actionSheetList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="抬头名称" prop="buyerName">
          <el-input v-model="invoices.buyerName"></el-input>
        </el-form-item>
        <el-form-item label="税号" prop="taxNum" v-if="iflookUptype == 2">
          <el-input v-model="invoices.taxNum"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="invoices.email"></el-input>
        </el-form-item>
        <el-form-item
          label="公司地址"
          prop="companyAddress"
          v-if="iflookUptype == 2"
        >
          <el-input v-model="invoices.companyAddress"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="invoices.mobile"></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="openBank" v-if="iflookUptype == 2">
          <el-input v-model="invoices.openBank"></el-input>
        </el-form-item>
        <el-form-item label="银行帐户" prop="bankNo" v-if="iflookUptype == 2">
          <el-input v-model="invoices.bankNo"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitInvoice()">提交</el-button>
          <el-button @click="cancleInvoice()">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 停车记录 -->
    <el-dialog
      title="查看停车记录"
      :visible.sync="isShowParking"
      width="60%"
      v-el-drag-dialog
    >
      <div class="txbj">
        <ul v-for="(item, index) in queryParkingList" :key="index">
          <li>
            车牌号:
            <span>{{
              item.pkInoutData.plateNo ? item.pkInoutData.plateNo : ''
            }}</span>
          </li>
          <li>
            停车流水号:
            <span>{{ item.inoutDataId ? item.inoutDataId : '' }}</span>
          </li>
          <li></li>
          <li>
            进场时间:
            {{
              item.pkInoutData.inTime
                ? conversionTime(item.pkInoutData.inTime)
                : ''
            }}
          </li>
          <li>
            离场时间:
            {{
              item.pkInoutData.outTime
                ? conversionTime(item.pkInoutData.outTime)
                : ''
            }}
          </li>
          <li>
            停车时长:
            {{
              item.pkInoutData.inTime && item.pkInoutData.outTime
                ? timeFn(item.pkInoutData.inTime, item.pkInoutData.outTime)
                : ''
            }}
          </li>
          <li>
            进场通道:
            {{ item.pkInoutData.inDoorName ? item.pkInoutData.inDoorName : '' }}
          </li>
          <li>
            离场通道:
            {{
              item.pkInoutData.outDoorName ? item.pkInoutData.outDoorName : ''
            }}
          </li>
          <li>
            应付金额:
            {{ item.actuallyPayMoney ? item.actuallyPayMoney : '0:00' }}
          </li>
          <li>
            <div>
              进场照片:
              <span
                ><img
                  :src="
                    item.pkInoutData.inPicPath ? item.pkInoutData.inPicPath : ''
                  "
                  width="500"
              /></span>
            </div>
            <div>
              离场照片:
              <span
                ><img
                  :src="
                    item.pkInoutData.outPicPath
                      ? item.pkInoutData.outPicPath
                      : ''
                  "
                  width="500"
              /></span>
            </div>
          </li>
        </ul>
      </div>
    </el-dialog>
    <!-- 支付日志 -->
    <el-dialog
      title="查看支付日志"
      :visible.sync="isShowPayment"
      width="70%"
      v-el-drag-dialog
    >
      <el-table
        :data="queryPaymentList"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="编号" width="70"></el-table-column>
        <el-table-column
          prop="payOrderNo"
          label="订单号"
          width="140"
        ></el-table-column>
        <el-table-column
          prop="orderPayMoney"
          label="支付金额"
        ></el-table-column>
        <el-table-column prop="changeMoney" label="找零">
          <template slot-scope="scope">
            <span>{{
              scope.row.changeMoney == null ? 0 : scope.row.changeMoney
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="paidType" label="支付方式">
          <template slot-scope="scope">
            <span v-if="scope.row.paidType">{{
              findQuery(scope.row.paidType, payment)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payScene" label="支付场景">
          <template slot-scope="scope">
            <span v-if="scope.row.payScene">{{
              findQuery(scope.row.payScene, payScene)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payStatus" label="支付状态">
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus">{{
              findQuery(scope.row.payStatus, payStatus)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="发起时间" width="140">
          <template slot-scope="scope">
            <span>{{
              scope.row.createTime ? conversionTime(scope.row.createTime) : ''
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="returnTime" label="支付完成时间" width="140">
          <template slot-scope="scope">
            <span>{{
              scope.row.returnTime ? conversionTime(scope.row.returnTime) : ''
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.remarks ? scope.row.remarks : '无' }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 订单详情 -->
    <el-dialog
      title="查看订单详情"
      :visible.sync="isShowOrder"
      width="60%"
      v-el-drag-dialog
    >
      <div class="txbj">
        <ul v-if="queryOrderList">
          <li>
            订单号:
            <span>{{
              queryOrderList.orderNo ? queryOrderList.orderNo : ''
            }}</span>
          </li>
          <li>
            停车流水号:
            <span>{{ queryOrderList.id ? queryOrderList.id : '' }}</span>
          </li>
          <li v-show="queryOrderList.payStatus === 0">支付状态: 未支付</li>
          <li v-show="queryOrderList.payStatus === 1">支付状态: 支付成功</li>
          <li v-show="queryOrderList.payStatus === 2">支付状态: 支付失败</li>
          <li v-show="queryOrderList.payStatus === 3">支付状态: 部分支付</li>
          <li>
            进场时间:
            {{
              queryOrderList.pkInoutDataDto.inTime
                ? conversionTime(queryOrderList.pkInoutDataDto.inTime)
                : ''
            }}
          </li>
          <li>
            离场时间:
            {{
              queryOrderList.pkInoutDataDto.outTime
                ? conversionTime(queryOrderList.pkInoutDataDto.outTime)
                : ''
            }}
          </li>
          <li>
            停车时长:
            {{
              queryOrderList.pkInoutDataDto.inTime &&
              queryOrderList.pkInoutDataDto.outTime
                ? timeFn(
                    queryOrderList.pkInoutDataDto.inTime,
                    queryOrderList.pkInoutDataDto.outTime
                  )
                : ''
            }}
          </li>
          <li>总金额: {{ queryOrderList.orderTotalMoney }}</li>
          <li>优惠金额: {{ queryOrderList.couponMoney }}</li>
          <li>已付金额: {{ queryOrderList.actuallyPayMoney }}</li>
        </ul>
      </div>
      <el-table
        :data="queryOrderLists"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="regionName" label="停车区域"></el-table-column>
        <el-table-column prop="inDate" label="进场时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.inDate">{{
              conversionTime(scope.row.inDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="outDate" label="出场时间" width="150">
          <template slot-scope="scope">
            <span v-if="scope.row.outDate">{{
              conversionTime(scope.row.outDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="pkDate" label="停车时长" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.outDate">{{
              timeFn(scope.row.inDate, scope.row.outDate)
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderMoney" label="停车金额"></el-table-column>
      </el-table>
    </el-dialog>
    <!-- 开票记录 -->
    <el-dialog
      title="查看开票记录"
      :visible.sync="isShowKPJL"
      width="70%"
      v-el-drag-dialog
    >
      <el-table
        :data="listByOrderIdList"
        stripe
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          prop="orderId"
          label="编号"
          width="70"
        ></el-table-column>
        <el-table-column
          prop="orderNo"
          label="订单号"
          width="140"
        ></el-table-column>
        <el-table-column prop="fpqqlsh" label="流水号"></el-table-column>
        <el-table-column prop="invoiceProvider" label="开票提供方">
          <template slot-scope="scope">
            <span v-if="scope.row.invoiceProvider === 1">诺诺网</span>
            <span v-if="scope.row.invoiceProvider === 2">百旺</span>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="开票时间">
          <template slot-scope="scope">
            <span>{{
              scope.row.createTime ? conversionTime(scope.row.createTime) : ''
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceStatus" label="开票状态">
          <template slot-scope="scope">
            <span v-if="scope.row.invoiceStatus === 0">未开票</span>
            <span v-if="scope.row.invoiceStatus === 1">已开票</span>
            <span v-if="scope.row.invoiceStatus === 2">开票失败</span>
          </template>
        </el-table-column>
        <el-table-column prop="invoiceReturnInfo" label="开票返回信息">
          <template slot-scope="scope">
            <span
              style="color: #409eff; cursor: pointer"
              v-if="scope.row.invoiceReturnInfo"
              @click="yexyClick(scope.row.invoiceReturnInfo)"
              >...展开</span
            >
          </template>
        </el-table-column>
      </el-table>
      <!--  -->
      <span slot="footer" class="dialog-footer" v-if="invoiceStatus === 2">
        <!--  -->
        <el-button
          type="primary"
          @click="regainStatus(listByOrderIdList[0].orderId)"
          >恢复可开票状态</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="开票返回信息"
      :visible.sync="isShowyexy"
      width="50%"
      v-el-drag-dialog
    >
      <span>{{ kpjlText }}</span>
    </el-dialog>
  </div>
</template>

<script>
import {
  newOrderInfo,
  selectByInoutDatatk,
  selectByPayLogtk,
  selectOrderInfotk,
  orderInfoexcel,
  orderQueryDataBill,
  userGroupTransfer,
  exportOrderQuertData,
  listByOrderId,
  regainStatus,
  orderPyaLogRedress,
  manualInvoice,
} from '@/api/record.js'
import { sysDictData } from '@/api/dictionaries.js'
import { findPkInfoSelect } from '@/api/Parkinglot.js'
export default {
  data() {
    var checkcompanyAddress = (rule, value, callback) => {
      const ompanyAddresskReg =
        /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/
      if (!value) {
        // return callback(new Error('手机号不能为空'))
        return callback()
      }
      setTimeout(() => {
        if (ompanyAddresskReg.test(value)) {
          callback()
        } else {
          callback(new Error('公司地址格式不正确'))
        }
      }, 100)
    }
    var checkOpenBank = (rule, value, callback) => {
      const OpenBankReg =
        /^(?:[\u3400-\u4DB5\u4E00-\u9FEA\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])+$/
      if (!value) {
        // return callback(new Error('手机号不能为空'))
        return callback()
      }
      setTimeout(() => {
        if (OpenBankReg.test(value)) {
          callback()
        } else {
          callback(new Error('开户行格式不正确'))
        }
      }, 100)
    }
    var checkBankNo = (rule, value, callback) => {
      const BankNoReg = /^[1-9]\d{9,29}$/
      if (!value) {
        // return callback(new Error('手机号不能为空'))
        return callback()
      }
      setTimeout(() => {
        if (BankNoReg.test(value)) {
          callback()
        } else {
          callback(new Error('银行帐户格式不正确'))
        }
      }, 100)
    }
    var checkMobile = (rule, value, callback) => {
      const phoneReg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (!value) {
        // return callback(new Error('手机号不能为空'))
        return callback()
      }
      setTimeout(() => {
        if (!Number.isInteger(+value)) {
          callback(new Error('请输入数字值'))
        } else {
          if (phoneReg.test(value)) {
            callback()
          } else {
            callback(new Error('手机号格式不正确'))
          }
        }
      }, 100)
    }
    var checkEmail = (rule, value, callback) => {
      const mailReg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (!value) {
        return callback(new Error('邮箱不能为空'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确的邮箱格式'))
        }
      }, 100)
    }
    var checktaxNum = (rule, value, callback) => {
      const mailReg =
        /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/
      if (!value) {
        return callback(new Error('请输入税号'))
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback()
        } else {
          callback(new Error('请输入正确税号格式'))
        }
      }, 100)
    }
    return {
      showHandover: true,
      isShowyexy: false,
      isShowKPJL: false,
      isShowloading: false,
      isShowData: false,
      isShowParking: false,
      isShowPayment: false,
      isShowOrder: false,
      isShowInvoice: false,
      tableData: [],
      inTime: undefined, // 开始时间
      outTime: undefined, // 结束时间
      query: {
        // 查询条件
        current: 1,
        size: 10,
        pk_id: undefined,
        t_year: undefined,
        plateNo: undefined, // 车牌号
        payStatus: undefined, // 支付状态
        orderNo: undefined, // 订单号
        isCoupon: undefined,
        inTime: undefined, // 开始时间
        outTime: undefined, // 结束时间
        plateType: undefined, // 车辆类型
        paidType: undefined, // 支付方式
        userGroupId: undefined, // 用户分组
        sortField: 'order_time', // 订单时间order_time  订单金额order_total_money
        sortType: 'desc', // 排序类型 默认desc  asc升序 desc降序
        invoiceStatus: undefined, //开票状态
        inoutStatus: 1,
        freeFlag: 0, //0元订单
      },
      total: undefined,
      PkInfoSelect: [], // 保存停车场下拉框
      queryParkingList: undefined, // 保存停车记录弹框数据
      queryPaymentList: [], // 保存停车记录弹框数据
      queryOrderList: undefined, // 保存停车记录弹框数据
      queryOrderLists: undefined, // 保存停车记录弹框数据
      payScene: [], // 保存支付场景
      payment: [], // 保存支付方式
      invoice: ['未开票', '开票成功', '开票失败', '开票中'], // 保存开票状态
      payStatus: [], // 订单日志支付状态
      payStatusdd: [], // 订单支付状态
      userGroupId: [], // 用户分组
      plateType: [], // 车辆类型
      cashzje: undefined, //总金额
      listByOrderIdList: [], // 开票记录
      kpjlText: undefined,
      invoiceStatus: undefined,
      showCorrect: false, //是否显示纠正跨天订单
      billshowId: [
        {
          name: '显示',
          id: 0,
        },
        {
          name: '不显示',
          id: 1,
        },
      ],
      invoices: {
        buyerName: undefined,
        taxNum: undefined,
        email: undefined,
        mobile: undefined,
        companyAddress: undefined,
        invoiceType: '个人',
        openBank: undefined,
        bankNo: undefined,
        orderId: [],
      },
      iflookUptype: 1,
      rulesinvoice: {
        buyerName: [
          { required: true, message: '请输入抬头名称', trigger: 'blur' },
        ],
        taxNum: [
          { required: true, message: '请输入税号', trigger: 'blur' },
          { validator: checktaxNum, trigger: 'blur' },
        ],
        companyAddress: [
          { required: false, message: '请输入公司地址', trigger: 'blur' },
          { validator: checkcompanyAddress, trigger: 'blur' },
        ],
        mobile: [
          { required: false, message: '请输入手机号', trigger: 'blur' },
          { validator: checkMobile, trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' },
        ],
        openBank: [
          { required: false, message: '请输入开户行', trigger: 'blur' },
          { validator: checkOpenBank, trigger: 'blur' },
        ],
        bankNo: [
          { required: false, message: '请输入银行帐户', trigger: 'blur' },
          { validator: checkBankNo, trigger: 'blur' },
        ],
      },
      actionSheetList: [
        {
          value: '个人',
          label: '个人',
        },
        {
          value: '单位',
          label: '单位',
        },
      ],
    }
  },
  activated() {
    this.findPkInfoSelect()
    if (this.$store.getters.getshowCorrect == true) {
      this.showCorrect = true
    }
  },
  created() {},
  computed: {
    // 控制年份日期范围
    pickerOptions() {
      const currentDate = new Date()
      const currentYear = new Date().getFullYear()
      // 获取当前月份和日期
      let currentMonth = currentDate.getMonth() + 1
      let currentDay = currentDate.getDate()
      // 检查是否大于等于6月1日
      let isAfterJune =
        currentMonth > 6 || (currentMonth === 6 && currentDay >= 1)
      return {
        disabledDate(time) {
          return isAfterJune
            ? time.getFullYear() < 2020 || time.getFullYear() > currentYear
            : time.getFullYear() < 2020 ||
                time.getFullYear() == currentYear - 1 ||
                time.getFullYear() > currentYear
        },
      }
    },
  },
  methods: {
    // 更改发票税号类型
    changeInvoiceType(value) {
      this.$refs.formName.clearValidate()
      this.invoices.taxNum = undefined
      this.invoices.companyAddress = undefined
      this.invoices.invoiceType = value
      this.invoices.openBank = undefined
      this.invoices.bankNo = undefined
      if (value == '个人') {
        this.iflookUptype = 1
      } else {
        this.iflookUptype = 2
      }
    },
    tYearchange(value) {
      if (value == null) {
        this.query.t_year = undefined
      } else {
        this.query.t_year = value
      }
    },
    invoiceclose() {
      this.invoices = {
        buyerName: undefined,
        taxNum: undefined,
        email: undefined,
        mobile: undefined,
        companyAddress: undefined,
        invoiceType: '个人',
        openBank: undefined,
        bankNo: undefined,
      }
    },
    // 纠正跨天订单
    correctOrder() {
      orderPyaLogRedress({ pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code == '200') {
          this.$message.success('纠正成功')
        } else {
          this.$message.error('纠正失败')
        }
      })
    },
    pkIdChange(item) {
      this.userGroupTransfer()
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 != 0) {
        return 'warning-row'
      }
      return ''
    },
    getTimeFn() {
      // 默认显示前两天的数据
      this.inTime =
        new Date(new Date().toLocaleDateString()).getTime() - 3600 * 1000 * 24
      this.outTime =
        new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
      this.newOrderInfo()
    },
    userGroupTransfer() {
      userGroupTransfer(
        { pk_id: this.query.pk_id },
        { pk_id: this.query.pk_id }
      ).then((res) => {
        this.userGroupId = res.data.data
      })
    },
    findQuery(code, list) {
      // 翻译
      const item = list.find((item) => item.code == code)
      return item ? item.name : ''
    },
    findQueryzfzt(code, dncode, list) {
      // 翻译支付状态
      const item = list.find((item) => item.code == code)
      if (item) {
        if (item.code == 0 && dncode === 1) {
          return '城市大脑支付中'
        } else if (item.code == 0 && dncode !== 1) {
          return '未支付'
        } else if (item.code != 0) {
          return item.name
        }
      }
    },
    inTimechange() {
      this.outTime = this.inTime + 3600 * 1000 * 24 * 31 - 1000
    },
    outTimechange() {
      if (this.inTime === null || this.inTime === undefined) {
        this.outTime = null
        return this.$message.warning('请选择开始时间')
      } else if (this.outTime < this.inTime) {
        this.outTime = this.inTime + 3600 * 1000 * 24 * 1
        return this.$message.warning('不可选择小于开始时间的日期')
      } else if (this.outTime - this.inTime > 3600 * 1000 * 24 * 31) {
        this.outTime = this.inTime + 3600 * 1000 * 24 * 31 - 1000
        return this.$message.warning('不可选择比开始时间超过一个月以外的日期')
      }
    },
    orderInfoexcel() {
      if (this.$route.query.totalTime) {
        this.query.inTime = this.$route.query.totalTime
        this.query.outTime =
          parseInt(this.$route.query.totalTime) + 24 * 3600 * 1000
      } else {
        if (this.outTime - this.inTime > 3600 * 1000 * 24 * 31) {
          return this.$message.warning('只能导出一个月以内的数据')
        }
        this.query.inTime = this.inTime
        this.query.outTime = this.outTime
        this.isShowloading = true
      }
      if (this.query.outTime || this.query.inTime) {
        this.query.outTime = parseInt(this.query.outTime / 1000).toFixed(0)
        this.query.inTime = parseInt(this.query.inTime / 1000).toFixed(0)
      }
      if (!this.query.inTime || !this.query.outTime) {
        return this.$message.warning('请选择导出的具体时间')
      }
      exportOrderQuertData(this.query, { pk_id: this.query.pk_id })
        .then((res) => {
          if (res.data.code === '200') {
            this.isShowloading = false
            window.open(res.data.data)
          }
        })
        .catch((err) => {
          this.isShowloading = false
        })
    },
    sortchange(column, prop, order) {
      if (column.prop == 'orderTime') {
        this.query.sortField = 'order_time'
      } else if (column.prop == 'orderTotalMoney') {
        this.query.sortField = 'order_total_money'
      } else if (column.prop == 'inTime') {
        this.query.sortField = 'in_time'
      } else if (column.prop == 'outTime') {
        this.query.sortField = 'out_time'
      }
      if (column.order == 'descending') {
        this.query.sortType = 'desc'
      } else if (column.order == 'ascending') {
        this.query.sortType = 'asc'
      }
      this.newOrderInfo()
    },
    // 计算两个时间差 dateBegin 开始时间
    timeFn(dateBegin, dateEnd) {
      //如果时间格式是正确的，那下面这一步转化时间格式就可以不用了
      var dateDiff = dateEnd * 1000 - dateBegin * 1000 //时间差的毫秒数时间戳
      var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)) //计算出相差天数
      var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000)
      var leave4 = leave3 % (60 * 1000) //计算分钟数后剩余的毫秒数
      var minseconds = Math.round(leave4 / 1000)
      // var timeFn = "耗时："+dayDiff+"天 "+hours+"小时 "+minutes+" 分钟"+seconds+" 秒"+minseconds+"毫秒";
      var timeFn =
        dayDiff + '天 ' + hours + '小时 ' + minutes + ' 分钟' + seconds + ' 秒'
      return timeFn
    },
    newOrderInfo() {
      const currentYear = new Date().getFullYear()
      if (this.query.t_year == currentYear) {
        this.query.t_year = undefined
      }
      // 进出记录查询列表
      if (this.$route.query.totalTime) {
        this.query.inTime = this.$route.query.totalTime
        this.query.outTime =
          parseInt(this.$route.query.totalTime) + 24 * 3600 * 1000
      } else {
        this.query.inTime = this.inTime
        this.query.outTime = this.outTime
      }
      if (this.query.inTime && !this.query.outTime) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.inTime && this.query.outTime) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.inTime || this.query.outTime) {
        this.query.inTime = parseInt(this.query.inTime / 1000).toFixed(0)
        this.query.outTime = parseInt(this.query.outTime / 1000).toFixed(0)
      }

      newOrderInfo(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.total = res.data.data.total
        this.tableData = res.data.data.list
        orderQueryDataBill(this.query, { pk_id: this.query.pk_id }).then(
          (res) => {
            this.isShowData = true
            this.cashzje = res.data.data
          }
        )
      })
    },
    orderQueryDatas() {
      // 进出记录查询列表
      if (this.$route.query.totalTime) {
        this.query.inTime = this.$route.query.totalTime
        this.query.outTime =
          parseInt(this.$route.query.totalTime) + 24 * 3600 * 1000
      } else {
        this.query.inTime = this.inTime
        this.query.outTime = this.outTime
      }
      if (this.query.inTime && !this.query.outTime) {
        return this.$message.warning('请输入截止时间')
      } else if (!this.query.inTime && this.query.outTime) {
        return this.$message.warning('请输入开始时间')
      }
      if (this.query.inTime || this.query.outTime) {
        this.query.inTime = parseInt(this.query.inTime / 1000).toFixed(0)
        this.query.outTime = parseInt(this.query.outTime / 1000).toFixed(0)
      }
      newOrderInfo(this.query, { pk_id: this.query.pk_id }).then((res) => {
        this.total = res.data.data.total
        this.tableData = res.data.data.list
      })
    },
    orderInfocx() {
      // 查询
      this.cashzje = undefined
      this.tableData = []
      this.query.current = 1
      this.newOrderInfo()
    },
    orderInfocz() {
      // 重置
      this.query.pk_id = this.PkInfoSelect[0].id
      this.query.plateNo = undefined
      this.query.payStatus = undefined
      this.query.orderNo = undefined
      this.inTime = undefined
      this.outTime = undefined
      this.query.plateType = undefined
      this.query.paidType = undefined
      this.query.userGroupId = undefined
      this.newOrderInfo()
    },
    sysDictData() {
      // 字典
      sysDictData({ typeId: 86 }).then((res) => {
        //支付方式
        this.payment = res.data.data
        sysDictData({ typeId: 97 }).then((res) => {
          //支付场景
          this.payScene = res.data.data
          sysDictData({ typeId: 94 }).then((res) => {
            //订单日志支付状态
            this.payStatus = res.data.data
            sysDictData({ typeId: 85 }).then((res) => {
              //订单支付状态
              this.payStatusdd = res.data.data.filter(function (item) {
                return item.name != 'FREE'
              })
              sysDictData({ typeId: 40 }).then((res) => {
                //车牌类型
                this.plateType = res.data.data
              })
            })
          })
        })
      })
    },
    findPkInfoSelect() {
      // 停车场下拉框
      findPkInfoSelect({ allRecord: 0 }).then((res) => {
        if (res.data.code === '200') {
          this.PkInfoSelect = res.data.data
          if (this.$route.query.pkId) {
            this.query.pk_id = parseInt(this.$route.query.pkId)
          } else {
            this.query.pk_id = this.PkInfoSelect[0].id
          }
          this.getTimeFn()
          this.sysDictData()
          this.userGroupTransfer()
        }
      })
    },
    // 手动开票
    invoiceManual(item) {
      this.invoices.orderId.push(item.id)
      this.isShowInvoice = true
      this.invoices.invoiceType = '个人'
      this.iflookUptype = 1
    },
    submitInvoice() {
      this.$refs.formName.validate((valid) => {
        if (valid) {
          let head = { pk_id: this.query.pk_id }
          manualInvoice(this.invoices, head).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('提交成功')
            }
          })
        } else {
          return false
        }
      })
    },
    cancleInvoice() {
      this.isShowInvoice = false
      this.invoices = {}
    },
    queryParkings(id) {
      // 停车记录
      selectByInoutDatatk({ id: id }, { pk_id: this.query.pk_id }).then(
        (res) => {
          if (res.data.code === '200') {
            this.queryParkingList = res.data.data
            this.isShowParking = true
          }
        }
      )
    },
    queryPayment(id) {
      // 支付日志
      selectByPayLogtk({ id: id }, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          this.queryPaymentList = res.data.data
          this.isShowPayment = true
        }
      })
    },
    queryOrder(id) {
      // 订单详情
      selectOrderInfotk({ id: id }, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          this.queryOrderList = res.data.data
          this.queryOrderLists = res.data.data.orderDetailDtos
          this.isShowOrder = true
        }
      })
    },
    billingRecord(id) {
      // 开票记录
      listByOrderId({ orderId: id }, { pk_id: this.query.pk_id }).then(
        (res) => {
          if (res.data.code === '200') {
            this.listByOrderIdList = res.data.data
            this.invoiceStatus = undefined
            if (res.data.data.length !== 0) {
              this.invoiceStatus = res.data.data[0].invoiceStatus
            }
            this.isShowKPJL = true
          }
        }
      )
    },
    yexyClick(text) {
      this.isShowyexy = true
      this.kpjlText = text
    },
    handleSizeChange(val) {
      this.query.size = val
      this.orderQueryDatas()
    },
    handleCurrentChange(val) {
      this.query.current = val
      this.orderQueryDatas()
    },
    regainStatus(id) {
      regainStatus({ orderId: id }, { pk_id: this.query.pk_id }).then((res) => {
        if (res.data.code === '200') {
          this.isShowKPJL = false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.deployment {
  color: #1677ff;
  cursor: pointer;
  margin-left: 10px;
}
.txbj {
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    li {
      width: 33.33333%;
      line-height: 40px;
      font-weight: 600;
      span {
        color: #409eff;
      }
      img {
        vertical-align: text-top;
      }
    }
  }
}
::v-deep .el-dialog__body {
  padding-top: 0px;
}
</style>
<style>
.el-dialog__wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
